/* eslint-disable @next/next/no-img-element */
import { Box, Button, Flex, Image } from '@chakra-ui/react';
import Link from 'next/link';
import React, { RefObject } from 'react';
import {
  getFaintBgByPartner,
  getPryBtnColorByPartner,
} from '../../components/homepage/partner-config';

const getTextByPartner = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return 'MyMTNPicknFix';
    case 'AIRNG':
      return 'Airtel PicknFix';
    default:
      return 'PicknFix';
  }
};
const getRf4ImgByPartener = () => {
  switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
    case 'MTNNG':
      return 'mtn-rf-4.webp';
    case 'AIRNG':
      return 'air-rf-4.webp';
    default:
      return 'pnf-rf4.webp';
  }
};

// const getColorByPartner = () => {
//   switch (process.env.NEXT_PUBLIC_PARTNER_CODE) {
//     case 'MTNNG':
//       return '#ffcb05';
//     case 'AIRNG':
//       return '#ffdfdf';
//     default:
//       return '#0F61D6';
//   }
// };

const rfViews = process.env.NEXT_PUBLIC_RF_VIEWS;

const RequestFixPage = ({
  sectionRef,
}: {
  sectionRef: RefObject<HTMLDivElement>;
}) => {
  return (
    <Box className="body-7" pt={5} id="request-fix" ref={sectionRef}>
      {/* navbar */}
      {/* <Navbar /> */}
      {/* section 0 */}

      {/* section 1 */}
      {rfViews?.includes('one') && (
        <Box
          as="section"
          className="section repair-section"
          maxW="1400px"
          mx="auto"
        >
          <Box className="container---main">
            <Box className="product-features-grid-section">
              <Box className="heading-one text-center" mb={0}>
                Request Repairs in 3 simple steps
              </Box>
              <Box className="product-grid-thirds request-repair">
                <Box
                  id="w-node-_8aae2444-f0bc-d46a-2b79-3cc7bd82ad1d-df636b5b"
                  data-w-id="8aae2444-f0bc-d46a-2b79-3cc7bd82ad1d"
                >
                  <Box className="product-feature-widget-title">
                    <Box className="widget-square-box request-repair">
                      <Box className="widget ai-notification-widget">
                        <Box className="badge ai-notification-badge">
                          <img
                            src="images/icon-star.svg"
                            alt=""
                            width="13"
                            height="12"
                          />
                          <Box className="text-bold">
                            <strong>Step 1</strong>
                          </Box>
                        </Box>
                        <Box className="heading-three">
                          Create An Account &amp; Choose Your Device Type!
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box className="widget-square-box request-repair">
                  <Box className="widget ai-notification-widget">
                    <Box className="badge ai-notification-badge">
                      <img
                        src="images/icon-star.svg"
                        alt=""
                        width="13"
                        height="12"
                      />
                      <img
                        src="images/icon-star.svg"
                        alt=""
                        width="13"
                        height="12"
                      />
                      <Box className="text-bold">
                        <strong>Step 2</strong>
                      </Box>
                    </Box>
                    <Box className="heading-three">
                      Scheduled your device for Pick Up &amp; Fixing!
                    </Box>
                  </Box>
                </Box>
                <Box className="widget-square-box request-repair">
                  <Box className="widget ai-notification-widget">
                    <Box className="badge ai-notification-badge">
                      <img
                        src="images/icon-star.svg"
                        alt=""
                        width="13"
                        height="12"
                      />
                      <img
                        src="images/icon-star.svg"
                        alt=""
                        width="13"
                        height="12"
                      />
                      <img
                        src="images/icon-star.svg"
                        alt=""
                        width="13"
                        height="12"
                      />
                      <Box className="text-bold">
                        <strong>Step 3</strong>
                      </Box>
                    </Box>
                    <Box className="heading-three">
                      Track the entire process from pickup to drop-off.
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* section 2 */}
      {rfViews?.includes('two') && (
        <Box as="section" className="section">
          <Box className="container---main">
            <Box className="feature-grid-section">
              <Box className="heading-two feature-grid-heading">
                Get all your devices fixed the {getTextByPartner()} way.
              </Box>
              <Box className="small-features-grid-thirds">
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Fast and reliable</Box>
                  </Box>
                  <Box className="text-block-22">
                    Fastest repair Turn Around Time by manufacturer approved
                    after sales support services centres
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Quality assured</Box>
                  </Box>
                  <Box className="text-block-23">
                    All repairs undertaken according to high standards set by
                    your device’s manufacturer. Quality repairs delivered every
                    time
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Unrivalled delivery</Box>
                  </Box>
                  <Box className="text-block-24">
                    Access 24/7 repairs updates through multiple channels so you
                    know exactly when your device is coming back to you
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Manufacturer Approved SC</Box>
                  </Box>
                  <Box className="text-block-25">
                    Every single service center on the {getTextByPartner()}{' '}
                    network has been approved and vetted by the manufacturer of
                    your device to offer in and out of warranty services.
                  </Box>
                </Box>
                <Box className="small-feature-box reduced-padding">
                  <Box className="small-feature-box-icon-title">
                    <img
                      src="images/interface-icon-check.svg"
                      alt=""
                      width="48"
                      height="48"
                    />
                    <Box className="heading-four">Varieties of devices</Box>
                  </Box>
                  <Box className="text-block-26">
                    Fix Desktop, Laptop Computers, Routers, Tablets, Mobile
                    Phones, TVs, Mifis, Printers, Monitors, Gaming Consoles,
                    Microwaves, Cameras, Scanners and many more
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* section 3 */}
      {rfViews?.includes('three') && (
        <Box as="section" className="section">
          <Box className="container---main">
            <Box className="container---l align-center">
              <Box
                data-w-id="73ab47fe-f824-910b-1553-d6ebe0314abc"
                className="large-quote"
              >
                <Box className="heading-two large-quote-text">
                  &quot;{getTextByPartner()} App gives me the kind of
                  convenience of using Uber. Excellent stuff&quot;
                </Box>
                <img
                  src="images/photo-avatar-02_compressed.webp"
                  alt=""
                  className="quote-author-image"
                />
                <Box>
                  <strong>Olu Jacobs, Lagos Nigeria</strong>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {/* section 4 */}
      {rfViews?.includes('four') && (
        <>
          <Box as="section" bg={getFaintBgByPartner()} py={10} mb={10}>
            <Box maxW="1100px" mx="auto">
              <Flex
                flexDir={['column-reverse', 'row']}
                justify="space-between"
                p={[4, 4]}
                gap={10}
                align="center"
              >
                <Image
                  src={`images/${getRf4ImgByPartener()}`}
                  alt="request-a-fix"
                  borderRadius="30px"
                  className="boxed-image-section-image"
                />
                <Box className="boxed-image-section-subscribe fix">
                  <Box className="display-heading hero-cta-heading">
                    Request a fix today!
                  </Box>
                  <Box className="container---xs">
                    <Box className="large-text large-text-2">
                      The trusted and convenient way to fix your devices from
                      the comfort of your home, city and country.
                    </Box>
                  </Box>
                  <Link
                    href="/request-fix/category"
                    // className="button bg-primary-2b w-inline-block"
                  >
                    <Button
                      bg={getPryBtnColorByPartner()}
                      fontWeight="bold"
                      py={6}
                      px={8}
                      borderRadius={50}
                      color="white"
                      w="full"
                      _hover={{ opacity: '0.8' }}
                    >
                      Request a fix
                    </Button>
                  </Link>
                </Box>
              </Flex>
            </Box>
          </Box>
        </>
      )}
      {/* footer */}
      {/* <Footer /> */}
    </Box>
  );
};

export async function getStaticProps() {
  return { props: {} };
}

export default RequestFixPage;
